import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileBelowWidth } from '@utilities/consts';
import Header from '@features/PageBuilder/components/Header';
import Container from '@components/Container/Container';
import { useEmblaCarousel } from 'embla-carousel/react';
import ConditionalWrapper from '@components/ConditionalWrapper';
import SliderPagination from '@components/SliderPagination';
import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';
import useWindowSize from '@hooks/use-window-size';
import styles from './PriceList.module.css';
const {
  Root,
  PriceModelsWrapper,
  WhiteBackground,
  PriceModelBox,
  SliderContainer,
  SliderPagination: SliderPaginationClass,
} = styles;
import PriceModel from './components/PriceModel/PriceModel';
import { HeaderSettings } from '@shared/types';

interface Props {
  className?: string;
  group: 'recurring' | 'singlewash' | 'self-wash';
  header: string;
  subheader?: string;
  prices: object;
  campaignPrice: number;
  headerSettings: HeaderSettings;
}

const PriceList = ({
  className,
  header,
  subheader = '',
  prices = {},
  group = 'recurring',
  backgroundColor,
  business: isBusiness,
  campaignPrice = 0,
  isCampaign,
  headerSettings = {},
}: Props) => {
  const windowSize = useWindowSize();

  const isMobile = windowSize.width < isMobileBelowWidth;

  const classes = classnames(
    Root,
    {
      [WhiteBackground]: backgroundColor === 'white',
    },
    className
  );

  const [carouselIsActive, setCarouselIsActive] = useState(true);
  const priceModels =
    prices?.models.sort(
      ({ price: priceA }, { price: priceB }) => priceA - priceB
    ) ?? [];
  const indexOfPopular = priceModels.findIndex(({ isPopular }) => isPopular);

  const [emblaRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'start',
    startIndex: isMobile ? indexOfPopular : 0,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onInit = useCallback(() => {
    if (!embla) return;
    setCarouselIsActive(embla.slidesNotInView().length > 0);
  }, [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    if (!embla) return;
    embla.on('resize', embla.reInit).on('reInit', onInit);
    onInit();
  }, [embla, onInit]);

  // when window size changes, set carousel to be active
  // this will init embla, and it will then determine
  // if some elements is not in view, and if that's the case
  // it will initialize the embla slider. Otherwise nothing will happen.
  useEffect(() => {
    if (!embla) {
      setCarouselIsActive(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return (
    <Container className={classes}>
      <Header
        text={header}
        subheader={subheader}
        position="center"
        headerSettings={headerSettings}
      />
      {carouselIsActive && (
        <SliderPagination
          className={SliderPaginationClass}
          total={prices?.models.length}
          current={selectedIndex}
          scrollTo={scrollTo}
        />
      )}
      <div
        ref={carouselIsActive ? emblaRef : null}
        className={PriceModelsWrapper}
      >
        <ConditionalWrapper
          condition={carouselIsActive}
          wrapper={(children) => (
            <>
              <div className={SliderContainer}>{children}</div>
            </>
          )}
        >
          {priceModels.map((data) => (
            <PriceModel
              className={PriceModelBox}
              {...data}
              signupFee={prices.signup_fee}
              key={data.id}
              isBusiness={isBusiness}
              group={group}
              whiteBackground={backgroundColor === 'white'}
              isCampaign={isCampaign}
              campaignPrice={campaignPrice}
            />
          ))}
        </ConditionalWrapper>
      </div>
    </Container>
  );
};

export default PriceList;
