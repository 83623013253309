import classnames from 'classnames';
import Container from '@components/Container';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import SliderPagination from '@components/SliderPagination';
import ConditionalWrapper from '@components/ConditionalWrapper';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import Header from '@features/PageBuilder/components/Header';
import { formatPoint } from '@features/PageBuilder/utilities/format-point';
import { distance, sortLocations } from '@utilities/helpers/geo';
import { useInView } from 'react-hook-inview';
import useUserPosition from '@hooks/use-user-position';
import dynamic from 'next/dynamic';
import styles from './Locations.module.css';
const {
  Root,
  Text: TextClass,
  MapWrapper,
  LocationBoxesWrapper,
  MapContainer,
  SliderContainer,
  GrayBackground,
  SliderPagination: SliderPaginationClass,
} = styles;
import LocationCard from './components/LocationCard';
import { Features, HeaderSettings } from '@shared/types';
import { useAppSelector } from '@shared/store';
import { useDispatch } from 'react-redux';
import { setLocations } from '@shared/store/locations';
// import LocationsMap from '@features/PageBuilder/components/LocationsMap';
const LocationsMap = dynamic(
  () => import('@features/PageBuilder/components/LocationsMap')
);

const locationsFetcher = (url: string) => fetch(url).then((r) => r.json());

type LocationsFeatures =
  | 'show_map'
  | 'show_search_field'
  | 'show_specific_locations'
  | 'has_text_below_header'
  | 'is_campaign';

type SpecificLocationsTypes = 'near_point' | 'only_new' | 'only_selfwash';
interface Props {
  className?: string;
  header: string;
  subheader?: string;
  orderByDistance?: boolean;
  features?: LocationsFeatures[];
  locationsShowImages?: undefined | boolean;
  specificLocationsForMap: any;
  specificLocationsType?: SpecificLocationsTypes[];
  nearCoordinates:
    | {
        lat: number;
        lng: number;
      }
    | {};
  excludeIds: string[];
  backgroundColor?: 'white' | 'grey';
  headerSettings: HeaderSettings;
  forceInView?: boolean;
  text?: string;
  specificLocations: any;
}

const Locations = ({
  className = '',
  header,
  subheader = '',
  features = [],
  locationsShowImages,
  specificLocationsType = [],
  nearCoordinates = {},
  text,
  excludeIds = [],
  specificLocationsForMap,
  orderByDistance = true,
  backgroundColor = 'white',
  headerSettings = {},
  forceInView = false,
}: Props) => {
  const { locale } = useRouter();
  const [ref, inView] = useInView();
  const [wasInView, setWasInView] = useState(true || forceInView || inView); // temporary bugfix for slider: https://washworld.atlassian.net/browse/ITD-2757
  const dispatch = useDispatch();

  if (inView && !wasInView) {
    setWasInView(true);
  }

  const showLocations =
    features && features.includes('show_specific_locations');
  const showMap = features && features.includes('show_map');
  const nearPoint =
    specificLocationsType && specificLocationsType.includes('near_point');
  const onlyNew =
    specificLocationsType && specificLocationsType.includes('only_new');
  const onlySkip =
    specificLocationsType && specificLocationsType.includes('only_selfwash');

  const isCampaign = features && features.includes('is_campaign');

  const { options: { defaultMapPosition = {} } = {} } = useAppSelector(
    (state) => state.wordpress
  );

  const didFetchMapOptions = Object.keys(defaultMapPosition || {}).length > 0;

  const initialZoom = didFetchMapOptions ? +defaultMapPosition?.zoom : 7;

  const { userPosition } = useUserPosition();

  const initialCenter = {
    // default coordinates for map (uses ACF Google Map component from WordPress)
    lat: didFetchMapOptions ? defaultMapPosition?.latitude : 55.41,
    lng: didFetchMapOptions ? defaultMapPosition?.longitude : 12.34,
  };

  const cacheBuster = Math.floor(Date.now() / 100000);

  const [isLoading, setIsLoading] = useState(true);

  const [visibleLocation, setVisibleLocation] = useState(false);

  const [allLocations, setAllLocations] = useState([]);

  const [carouselIsActive, setCarouselIsActive] = useState(true);

  const [emblaRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'start',
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onInit = useCallback(() => {
    if (!embla) return;

    setCarouselIsActive(embla.slidesNotInView().length > 0);
  }, [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    if (!embla) return;
    embla.on('reInit', onInit);
    onInit();
  }, [embla, onInit]);

  useSWR(
    `${
      process.env.API_CLIENT_ENDPOINT
    }/wp-json/ww/v1/locations?country=${locale}&cacheBuster=${cacheBuster}&m=${
      subheader + header
    }`,
    locationsFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      revalidate: 60 * 60 * 1000,
      onSuccess: (locations) => {
        setAllLocations(locations);

        // setPoints(formattedPoints);
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    if (allLocations) {
      dispatch(setLocations(allLocations));
    }
  }, [allLocations]);

  const formattedPoints = useMemo(() => {
    let newFormattedPoints = (allLocations || []).map(formatPoint);

    // filter through locations
    if (onlyNew || onlySkip) {
      newFormattedPoints = newFormattedPoints.filter(
        ({ properties: { isNew, skip_count: skipCount } }) =>
          // filter through; if both values are enabled, both conditions have to be met
          // otherwise, only one of them
          onlyNew && onlySkip
            ? skipCount > 0 && isNew
            : (onlyNew && isNew) || (onlySkip && skipCount > 0)
      );
    }

    return newFormattedPoints;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations]);

  const locationBoxes = useMemo(() => {
    let locations = formattedPoints;

    const coordinates = userPosition;

    const { lat: userLat, lng: userLng } = coordinates || { lat: 0, lng: 0 };

    const pointLat = nearPoint ? nearCoordinates.lat : userLat;
    const pointLng = nearPoint ? nearCoordinates.lng : userLng;

    if (
      coordinates &&
      locations &&
      locations.length &&
      (orderByDistance || nearPoint)
    ) {
      locations = sortLocations(locations, { lat: pointLat, lng: pointLng });
    }

    if (onlyNew || onlySkip) {
      locations = locations.filter(
        ({ properties: { isNew, skip_count: skipCount } }) =>
          // filter through; if both values are enabled, both conditions have to be met
          // otherwise, only one of them
          onlyNew && onlySkip
            ? skipCount > 0 && isNew
            : (onlyNew && isNew) || (onlySkip && skipCount > 0)
      );
    }

    if (excludeIds && excludeIds.length) {
      locations = locations.filter(
        ({ properties: { uid } }) => !excludeIds.includes(uid)
      );
    }

    locations = locations.slice(0, 4);

    // if (orderByDistance || nearPoint) {
    locations = locations.map(
      ({
        geometry: {
          coordinates: [lng, lat],
        },
        properties,
        ...l
      }) => ({
        ...l,
        properties: {
          ...properties,
          distance:
            pointLat && pointLng ? distance(pointLat, pointLng, lat, lng) : 0,
        },
      })
    );
    // }

    return locations;
  }, [
    onlyNew,
    nearCoordinates,
    nearPoint,
    excludeIds,
    onlySkip,
    orderByDistance,
    formattedPoints,
    userPosition,
  ]);

  const classes = classnames(
    Root,
    {
      [GrayBackground]: backgroundColor === 'gray',
    },
    className
  );

  const locationBoxesOrSkeletons = isLoading
    ? new Array(4).fill({ isSkeleton: true })
    : locationBoxes;

  return (
    <div ref={ref} className={classes}>
      <Container>
        <Header
          headerSettings={headerSettings}
          text={header}
          subheader={subheader}
          position="center"
        />
        {text && features?.includes('has_text_below_header') && (
          <HTMLText className={TextClass}>{text}</HTMLText>
        )}
        {carouselIsActive && (
          <SliderPagination
            className={SliderPaginationClass}
            total={locationBoxesOrSkeletons.length}
            current={selectedIndex}
            scrollTo={scrollTo}
            isLoading={isLoading}
          />
        )}
        <div className={MapWrapper}>
          {showLocations ? (
            <div
              className={LocationBoxesWrapper}
              ref={carouselIsActive ? emblaRef : null}
            >
              <ConditionalWrapper
                condition={carouselIsActive}
                wrapper={(children) => (
                  <div className={SliderContainer}>{children}</div>
                )}
              >
                {locationBoxesOrSkeletons &&
                  locationBoxesOrSkeletons.map((location, index) => (
                    <LocationCard
                      key={JSON.stringify([location, index])}
                      clicked={(uid: string) =>
                        setVisibleLocation(Boolean(uid))
                      }
                      showImage={locationsShowImages}
                      showButtons={locationsShowImages}
                      isSkeleton={isLoading}
                      backgroundColor={
                        backgroundColor === 'white' ? 'gray' : 'white'
                      }
                      {...location.properties}
                    />
                  ))}
              </ConditionalWrapper>
            </div>
          ) : null}
          {/* Wait for defaultMapPosition data to be rendered before loading the map, this ensures initialZoom and initialCenter is set correctly */}
          {wasInView && didFetchMapOptions && (
            <LocationsMap
              loadMap={showMap}
              initialCenter={initialCenter}
              initialZoom={initialZoom}
              className={MapContainer}
              isCampaign={isCampaign}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              visibleLocation={visibleLocation}
              specificLocations={specificLocationsForMap}
              points={formattedPoints}
              fetchPosition={false}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default Locations;
