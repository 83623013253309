export interface ExtendedWindow extends Window {
  dataLayer?: Array<any>;
}

export interface SnackBarData {
  message: string;
  duration?: number;
}

export interface ServiceUnits {
  hall: {
    total_count: number;
    machine_type: string;
  };
  self_wash: {
    total_count: number;
    details: {
      uid: string;
      name: string;
      service_unit_model_name: string;
    }[];
  };
  mat_cleaner: {
    total_count: number;
    details: {
      uid: string;
      name: string;
      service_unit_model_name: string;
    }[];
  };
  vacuum: {
    total_count: number;
    details: {
      uid: string;
      name: string;
      service_unit_model_name: string;
    }[];
  };
  pre_wash: {
    total_count: number;
    details: never[];
  };
}

export interface SingleLocation {
  uid: string;
  Location_id: string;
  name: string;
  address: string;
  coordinates: {
    y: string;
    x: string;
  };
  open_hours: string;
  grand_opening: string;
  soft_opening: string;
  washhall_size: string;
  max_height: string;
  wheel_width: string;
  mirror_length: string;
  wash_name: string;
  country_id: string;
  region: string;
  region_name: string;
  has_addons: string;
  service_units: ServiceUnits;
  infoscreen: string;
  message: string;
  hidden: number;
}

export enum ServiceUnitType {
  ROLL_OVER = 1,
  SELF_WASH = 2,
  MATCLEANER = 3,
  VACUUM = 4,
  HIGH_PRESSURE_PRE_WASH = 5,
  PREWASH = 6,
}

export enum ServiceUnitUids {
  CHRIST_COMPACT_VACUUM = '73d9632d-9457-4960-9b51-bc2cefd97aba',
}
export enum LicensePlateErrors {
  ALREADY_EXISTS = 'licensePlateAlreadyExist',
  MINUMUM_LENGTH = 'minPlateLength',
  MAXIMUM_LENGTH = 'maxPlateLength',
  IS_TAXA = 'taxa',
  MISSING_VALUE = 'missing license_plate value',
}

export enum PaymentTypes {
  CHECKOUT = 1,
  UPDATE_CARD = 2,
  CHECKOUTSINGLEWASH = 3,
  PIP_ADD_CARD = 4,
  NEW_CHECKOUT = 5,
  CAMPAIGN_CHECKOUT = 6,
}
